import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Admin.vue'
import CodeList from "../views/CodeList";
import Login from "../views/Login";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/login',
        component: Login
    },
    {
        path: '/code',
        component: Home,
        children: [
            {path: 'list', component: CodeList}
        ]
    }
]

const router = new VueRouter({
    routes,
})
export default router
