<template>
    <v-app id="inspire">
        <v-app-bar>
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"/>
            <v-toolbar-title>xuân tiến</v-toolbar-title>
            <v-spacer/>
            <v-btn icon @click="logout">
                <v-icon>mdi-logout</v-icon>
            </v-btn>
        </v-app-bar>
        <v-navigation-drawer v-model="drawer" app class="pt-4" color="grey lighten-3" style="color: white"
                             mini-variant mini-variant-width="120">
          <v-app-bar-nav-icon @click.stop="drawer = !drawer"/>
            <v-sheet color="grey lighten-4" class="pa-4 d-flex align-center flex-column">
                <v-avatar class="mb-4" color="grey darken-1" size="64"></v-avatar>
                <div class="text-center">Hi</div>
            </v-sheet>

            <v-divider></v-divider>
            <v-list>
                <v-list-item-group v-model="selectMenu" @change="changeMenu">
                    <v-list-item color="primary" v-for="item in menu" :key="item.path" link>{{item.name}}</v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
        <v-main>
            <router-view/>
        </v-main>
    </v-app>
</template>

<script>
    import Api from "../util/Api";

    export default {
        data() {
            return {
                drawer: false,
                selectMenu: '',
                menu: [
                    {name: 'Code List', path: '/code/list'},
                ]
            }
        },
        created() {
            Api.codeList().catch(() => {
                this.$router.push({path: '/'})
            })
        },
        methods: {
            changeMenu(index) {
                if (index !== undefined) {
                    const path = this.menu[index].path
                    this.$router.push({path})
                }
            },
            logout() {
                Api.logout()
            }
        }
    }
</script>
