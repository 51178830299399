import Vue from 'vue'
import moment from "moment";
import 'moment/locale/en-nz'
Vue.filter('datetime', (value) => {
    if (!value) {
        return ''
    }
    return moment(value).format('yyyy-MM-DD HH:mm')
})


Vue.filter('dateYMD', (value) => {
    if (!value) {
        return ''
    }
    return moment(value).format('yyyy-MM-DD')
})


Vue.filter('dateMD', (value) => {
    if (!value) {
        return ''
    }
    return moment(value).format('MM-DD')
})
Vue.filter('fromNow', (value) => {
    if (!value) {
        return ''
    }
    return moment(value).fromNow()
})

Vue.filter('toNow', (value) => {
    if (!value) {
        return ''
    }
    return moment(value).toNow()
})
