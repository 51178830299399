import Vue from 'vue'
import ECharts from "vue-echarts";
import './plugins/axios'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import './util/filter'
Vue.config.productionTip = false

Vue.component('v-chart', ECharts)
new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
