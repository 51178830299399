<template>
  <v-app>
    <router-view />
    <v-snackbar :timeout="3000" v-model="showError">{{errorMessage}}</v-snackbar>
    <v-dialog v-model="showLoading" hide-overlay persistent title="Loading" max-width="500">
      <v-card>
        <v-card-title>Loading...</v-card-title>
        <v-card-text>
          <v-progress-linear indeterminate/>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog max-width="300" v-model="dialogConfirm.show">
      <v-card>
        <v-card-title>{{dialogConfirm.title}}</v-card-title>
        <v-card-text v-if="dialogConfirm.text">{{dialogConfirm.text}}</v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn @click="confirmDialogBtn(false)">Cancel</v-btn>
          <v-btn @click="confirmDialogBtn(true)">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import Api from "./util/Api";

export default {
  name: 'App',
  data: () => ({
    showError: false,
    showLoading: false,
    dialogConfirm: {
      show: false,
      title: '',
      text: '',
      confirmFunc: () => {}
    },
    errorMessage: 'errorMessage'
  }),
  created() {
    Api.registerGlobal(this.showErrorTipsFunc, this.showLoadingTipsFunc, this.showConfirmDialog)
  },
  methods: {
    showLoadingTipsFunc(loading){
      this.showLoading = loading
    },
    showErrorTipsFunc(message) {
      this.showError = true
      this.errorMessage = message
    },
    showConfirmDialog(title, text, confirmFunc) {
      this.dialogConfirm.show = true
      this.dialogConfirm.title = title
      this.dialogConfirm.text = text
      this.dialogConfirm.confirmFunc = confirmFunc
    },
    confirmDialogBtn(confirm) {
      this.dialogConfirm.show = false
      this.dialogConfirm.confirmFunc && this.dialogConfirm.confirmFunc(confirm)
    }
  }
};
</script>
