<template>
    <v-app>
        <v-dialog :value="true" max-width="500" persistent>
            <v-card>
                <v-card-title>xuân tiến</v-card-title>
                <v-card-text>
                    <v-form ref="form">
                        <v-row>
                            <v-col>
                                <v-text-field v-model="formLogin.username" placeholder="username" :rules="ruleNonEmpty"/>
                            </v-col>
                            <v-col>
                                <v-text-field v-model="formLogin.password" type="password" placeholder="password" :rules="ruleNonEmpty" @keyup.enter="clickLogin"/>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-card-actions>
                        <v-spacer/>
                        <v-btn color="primary" @click="clickLogin">Login</v-btn>
                    </v-card-actions>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
    import Api from "../util/Api";

    export default {
        name: "Login",
        data() {
            return {
                formLogin: {
                    username: '',
                    password: ''
                },
                ruleNonEmpty: [v => !!v || "Can't be empty"]
            }
        },
        created() {
            Api.codeList().then(() => {
                this.$router.push({path: '/code/list'})
            })
        },
        methods: {
            clickLogin() {
                if (this.$refs.form.validate()) {
                    Api.login(this.formLogin).then(() => {
                        this.$router.push({path: '/code/list'})
                    })
                }
            }
        }
    }
</script>

<style scoped>

</style>
