<template>
    <v-app class="pa-5">
        <v-data-table :headers="headers" :items="codeList" :items-per-page="9999" hide-default-footer>
            <template v-slot:top>
                <div>
                  <div>
                    <v-btn :color="adminConfig.closeServer ? 'primary' : 'error'" @click="ckCloseServerButton">{{ adminConfig.closeServer ? 'Open' : 'Close' }} Server</v-btn>
                    Server Status : {{ adminConfig.closeServer ? 'CLOSED': 'OPEN' }}
                  </div>
                  <div>
                    total: {{ codeCount.total }}
                    used: {{ codeCount.used }}
                  </div>
                    <v-btn @click="dialogAddCode = true">Add Code</v-btn>
                    &nbsp;
                    <v-btn @click="ckUnbindAll">Unbind All</v-btn>
                    &nbsp;
                    <v-text-field v-model="formSearch.code" label="Search code" @keyup.enter="search">
                        <template v-slot:append-outer>
                            <v-btn color="primary" @click="search">Search</v-btn>
                        </template>
                    </v-text-field>
                </div>
            </template>
            <template v-slot:item.dateActived="{value}">
                <div>{{value | datetime}}</div>
                <div>{{value | fromNow}}</div>
            </template>
            <template v-slot:item.dateExpired="{value}">
                <div>{{value | datetime}}</div>
                <div>{{value | fromNow}}</div>
            </template>
          <template v-slot:item.opt="{item}">
            <v-btn small @click="stopCode(item)">Stop</v-btn>
            <v-btn small @click="unbindCode(item)">Unbind</v-btn>
          </template>
        </v-data-table>
      <v-row justify="center" align-content="center">
        <v-col><v-btn @click="gotoPage(-1)">Previous Page</v-btn></v-col>
        <v-col><v-btn @click="gotoPage(1)">Next Page</v-btn></v-col>
      </v-row>
        <v-dialog v-model="dialogAddCode" :persistent="dialogPersistent" max-width="500">
            <v-card v-if="newCodes.length === 0">
                <v-card-title>Add Code</v-card-title>
                <v-card-text>
                    <v-form ref="formAddCode">
                        <v-container>
                            <v-row>
                                <v-col>
                                  <v-text-field label="Prefix" v-model="formAddCode.prefix" :rules="ruleNonEmpty"/>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-select v-model="formAddCode.size" :items="[10, 20, 50, 100, 5, 1]" label="num" :rules="ruleNonEmpty"/>
                                </v-col>
                                <v-col>
                                    <v-select v-model="formAddCode.day" :items="[1, 7, 14, 30]" label="day" :rules="ruleNonEmpty"/>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn color="primary" @click="confirmAddCode">Confirm</v-btn>
                </v-card-actions>
            </v-card>
            <v-card v-else>
                <v-card-title>Success</v-card-title>
                <v-card-text>
                    <v-textarea :value="newCodes" disabled/>
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="closeCodeDialog">Close</v-btn>
                    <v-spacer/>
                    <v-btn color="primary" @click="copyCode">Copy</v-btn>
                    <v-btn color="primary" @click="downloadCode">Download</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogCloseServer" title="Confirm Close Server">
          <v-card>
            <v-card-title>Confirm Close Server</v-card-title>
            <v-card-text>
              <v-text-field placeholder="tw vn all" v-model="formCloseServer.closeMessage"/>
            </v-card-text>
            <v-card-actions>
              <v-btn @click="dialogCloseServer = false">Cancel</v-btn>
              <v-spacer/>
              <v-btn color="primary" @click="ckConfirmCloseServer">Confirm</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
    import Api from "../util/Api";
    import copy from 'copy-to-clipboard'
    import FileSaver from 'file-saver'

    export default {
        name: "CodeList",
        data() {
            return {
                dialogAddCode: false,
                dialogPersistent: false,
                dialogCloseServer: false,
                headers: [
                    {text: 'Code', value: 'code'},
                    {text: 'Active', value: 'dateActived'},
                    {text: 'Expire', value: 'dateExpired'},
                    {text: 'Operation', value: 'opt'},
                ],
                codeList: [],
                codeCount: {
                  total: 0,
                  used: 0
                },
                adminConfig: {
                  closeServer: false,
                  closeMessage: ''
                },
                ruleNonEmpty: [v => !!v || "Can't be empty"],
                newCodes: '',
                formSearch: {
                    code: '',
                    page: 1,
                },
                formAddCode: {
                    size: '',
                    day: '',
                    prefix: ''
                },
                formCloseServer: {
                  closeMessage: ''
                },
                text:''
            }
        },
        created() {
            this.loadData()
        },
        methods: {
            search() {
                this.formSearch.page = 1
                this.loadData()
            },
            async loadData() {
                this.codeList = await Api.codeList(this.formSearch)
                this.codeCount = await Api.codeCount()
                this.adminConfig = await Api.adminConfig()
              console.log({
                admin: this.adminConfig
              })
            },
            async confirmAddCode() {
                if (this.$refs['formAddCode'].validate()) {
                    this.newCodes = await Api.codeCreate(this.formAddCode)
                    this.newCodes = this.newCodes.join('\n')
                    this.dialogPersistent = true
                }
            },
            closeCodeDialog() {
                this.dialogPersistent = false
                this.dialogAddCode = false
                this.newCodes = ''
            },
            copyCode() {
                if (copy(this.newCodes)) {
                    Api.error('Copy success')
                }
            },
            downloadCode() {
                if (copy(this.newCodes)) {
                    Api.error('Copy success')
                }
                const blob = new Blob([this.newCodes], {type: "text/plain;charset=utf-8"});
                FileSaver.saveAs(blob, `${this.formAddCode.prefix}-${this.formAddCode.size}-${this.formAddCode.day}天.txt`);
            },
          async stopCode(item) {
              if (await Api.confirm('Are you sure?')) {
                await Api.codeStop(item.code)
                await this.loadData()
              }
          },
          async unbindCode(item) {
            if (await Api.confirm('Are you sure?')) {
              await Api.codeUnbind(item.code)
              await this.loadData()
            }
          },
          gotoPage(change){
            this.formSearch.page += change
            if (this.formSearch.page < 1) {
              this.formSearch.page = 1;
            }
            this.loadData()
          },
          async ckUnbindAll() {
            if (await Api.confirm('Are you sure?')) {
              await Api.codeUnbindAll()
            }
          },
          ckCloseServerButton(){
              if (this.adminConfig.closeServer) {
                Api.adminCloseServer({closeServer: false, closeMessage: ''}).then(() => {
                  this.adminConfig.closeServer = false
                })
              } else {
                this.dialogCloseServer = true
              }
          },
          ckConfirmCloseServer() {
            let match = false
            for (const target of ['tw', 'vn', 'all']) {
              if (this.formCloseServer.closeMessage.indexOf(target) !== -1) {
                match = true
                break
              }
            }
            if (match) {
              Api.adminCloseServer({closeServer: true, closeMessage: this.formCloseServer.closeMessage}).then(() => {
                this.adminConfig.closeServer = true
                this.dialogCloseServer = false
              })
            } else {
              Api.error('Only support vn,tw,all')
            }
          }
        }
    }
</script>

<style scoped>

</style>
