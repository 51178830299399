import Axios from 'axios'
import qs from 'querystring'

const axios = Axios.create()
axios.interceptors.request.use((request) => {
    Api.loading(true)
    return request
})
axios.interceptors.response.use((response) => {
    Api.loading(false)
    return response.data;
}, error => {
    Api.loading(false)
    let message = ''
    if (error.response) {
        message = error.response.data.message
        if (error.response.data.bizCode === 40301) {
            location.href = '#/login'
        }
    }
    message = message || 'Network error'
    Api.error(message)
    return Promise.reject(message)
})
let _errorTipsHandle;
let _loadingHandle;
let _showConfirmDialog;
export default class Api {
    static registerGlobal(errorTipsHandle, loadingHandle, showConfirmDialog) {
        _errorTipsHandle = errorTipsHandle
        _loadingHandle = loadingHandle
        _showConfirmDialog = showConfirmDialog
    }
    static error(message) {
        _errorTipsHandle(message)
    }
    static loading(loadingValue) {
        _loadingHandle(loadingValue)
    }
    static confirm(title, text = '') {
        return new Promise((resolve => {
            _showConfirmDialog(title, text, (confirm) => {
                resolve(confirm)
            })
        }))
    }
    static async login(form) {
        await axios.post(`/api/pub/login`, qs.stringify(form))
    }
    static codeCreate(form) {
        return axios.post(`/api/admin/code/create`, qs.stringify(form))
    }
    static codeStop(code) {
        return axios.put(`/api/admin/code/stop`, qs.stringify({code}))
    }
    static codeList(params) {
        return axios.get(`/api/admin/code/list`, {
            params
        })
    }
    static codeUnbind(code) {
        return axios.put(`/api/admin/code/unbind`, qs.stringify({ code }))
    }
    static codeUnbindAll() {
        return axios.post(`/api/admin/code/unbind/all`)
    }
    static codeCount() {
        return axios.get(`/api/admin/code/count`)
    }
    static adminConfig() {
        return axios.get('/api/admin/config')
    }
    static adminCloseServer(form) {
        return axios.put('/api/admin/config/close-server', qs.stringify(form))
    }
    static async logout() {
        await axios.get('/api/pub/logout')
        location.href = '#/'
    }
}
